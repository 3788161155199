import './App.css';
import Home from './pages/home'; // Assuming your IndexPage component
import Origin from './pages/origin';
import Collaboration from './pages/collaboration';
import Auction from './pages/auction';
import Contact from './pages/contact';
import AppLayout from './components/Layout';

import { ConfigProvider } from 'antd';
import type { ThemeConfig } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Brandz from './pages/brandz';
import Media from 'pages/media';
import Newsroom from 'pages/newsroom';
import NotFound from 'components/NotFoundPage';
import Celebrity from 'pages/media/celebredity';
import Craft from 'pages/media/craft';
import Event from 'pages/media/events';
import EventDetails from 'pages/media/event_details';
import CraftDetails from 'pages/media/craft_details';
import ScrollToTop from 'components/ScrollStop';

const config: ThemeConfig = {
  token: {
    colorPrimary: '#FFD700',
    borderRadius: 2,
  },
};

function App() {
  return (
    <ConfigProvider theme={config}>
      <Router>
        <ScrollToTop />

        <Routes>
          {/* Use AppLayout as the wrapper for all routes */}
          <Route>
            {/* Define your routes */}
            <Route path="/" element={<Home />} />
            <Route element={<AppLayout />} >
              <Route path="origin" element={<Origin />} />
              <Route path="brands" element={<Brandz />} />
              <Route path="media" element={<Media />} />
              <Route path="media/events/" element={<Event />} />
              <Route path="media/events/:id" element={<EventDetails />} />
              <Route path="media/celebrities/" element={<Celebrity />} />
              {/* <Route path="media/celebrities/:id" element={<Celebrity />} /> */}
              <Route path="media/crafts/" element={<Craft />} />
              <Route path="media/crafts/:id" element={<CraftDetails />} />

              <Route path="newsroom" element={<Newsroom />} />
              <Route path="collaboration" element={<Collaboration />} />
              <Route path="auction" element={<Auction />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Route>

          </Route>
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
