import React from 'react';
import { Menu, Button } from 'antd';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// Define props for the FlexibleMenu component
interface FlexibleMenuProps {
  useButtons: boolean; // Boolean to toggle between Button and Menu styles
  vertical?: boolean; // Controls whether the menu is vertical or horizontal
  footerOnly?: boolean; // Controls whether to filter only footer items
}

const FlexibleMenu: React.FC<FlexibleMenuProps> = ({ useButtons = false, vertical = false, footerOnly = false }) => {
  // Get the current route path
  const location = useLocation();
  const currentPath = location.pathname;

  // Shared Menu items
  const menuItems = [
    { key: '0', label: 'Home', link: '/' },
    { key: '1', label: 'Origin', link: '/origin' },
    {
      key: '2',
      label: 'Brands',
      link: '/brands',
      subItems: [
        { key: '2-1', label: '1972nineteenseventytwo', link: '/brandz/1972' },
        { key: '2-2', label: '1972 Conscious', link: '/brandz/1972-conscious' },
        { key: '2-3', label: 'Zenena', link: '/brandz/zenena' },
        { key: '2-4', label: 'Meta Moina', link: 'http://www.metamoina.io', external: true, footer: true },
        { key: '2-5', label: 'Niharika Momtaz Production', link: 'https://www.facebook.com/profile.php?id=61553900793926&mibextid=ZbWKwL', external: true, footer: true },
      ],
    },
    { key: '3', label: 'Media', link: '/media' },
    { key: '4', label: 'News Room', link: '/newsroom', footer: true },
    { key: '5', label: 'Collaboration', link: '/collaboration', footer: true },
    { key: '6', label: 'Auction', link: '/auction', footer: true },
    { key: '7', label: 'Bangladesh Art Week', link: 'https://www.bangladeshartweek.com/', external: true, footer: true },
    { key: '8', label: 'Heritage Bangladesh Foundation', link: 'https://www.heritagebangladeshfoundation.com/', external: true, footer: true },
    { key: '9', label: 'Contact', link: '/contact' },
  ];

  // Filter the items based on footerOnly prop
  const filteredItems = footerOnly ? menuItems.filter(item => item.footer) : menuItems;

  if (useButtons) {
    // Render Button-based navigation
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: vertical ? 'column' : 'row',
          flexWrap: 'wrap',
          gap: '8px', // Space between buttons
          justifyContent: 'center', // Center the buttons
          alignItems: 'center',
          textAlign: 'center',
          width: '100%', // Make it responsive
          padding: '8px 0',
        }}
      >
        {filteredItems.map((item) =>
          item.external ? (
            <Button
              key={item.key}
              size="small"
              ghost
              style={{ fontSize: '12px', padding:'10px' }}
              className="custom-menu-button"
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                {item.label}
              </a>
            </Button>
          ) : (
            <Button
              key={item.key}
              size="small"
              ghost
              style={{ fontSize: '12px', padding:'10px' }}
              className="custom-menu-button"
            >
              <RouterLink to={item.link} style={{ color: 'white' }}>
                {item.label}
              </RouterLink>
            </Button>
          )
        )}
      </div>
    );
  } else {
    // Render Menu-based navigation
    return (
      <Menu
        mode={vertical ? "vertical" : "horizontal"}
        selectedKeys={[currentPath]}
        style={{
          backgroundColor: 'transparent',
          justifyContent: 'center', // Center the menu items
        }}
        className={footerOnly ? 'footer-menu' : ''}
      >
        {filteredItems.map((item) =>
          item.subItems ? (
            <Menu.SubMenu
              key={item.key}
              title={<RouterLink to={item.link} style={{ color: 'white' }}>{item.label}</RouterLink>} // Set the main item text color to white
            >
              {item.subItems.map(subItem => (
                <Menu.Item key={subItem.key} >
                  <RouterLink to={subItem.link}>{subItem.label}</RouterLink>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : item.external ? (
            <Menu.Item key={item.link} style={{ color: 'white' }}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.label}
              </a>
            </Menu.Item>
          ) : (
            <Menu.Item key={item.link} style={{ color: 'white' }}>
              <RouterLink to={item.link}>{item.label}</RouterLink>
            </Menu.Item>
          )
        )}
      </Menu>
    );
  }
};

export default FlexibleMenu;
