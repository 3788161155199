import React from 'react';
import { Space, Tooltip } from 'antd';
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram, FaPinterest, FaLinkedin } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import "../assets/css/custom.css"
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
  PinterestOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';
// Define props for the component
interface SocialMediaLinksProps {
  usePatternOne: boolean; // Boolean to toggle between pattern types
}
const color_sm = "#FFD700"; //golden
const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ usePatternOne }) => {
  // Social media items
  const socialMediaItems = [
    { key: 'facebook', icon: <FaFacebook size={24} color={color_sm} />, link: 'https://www.facebook.com/niharikamomtazofficial?mibextid=LQQJ4d', label: 'Facebook' },
    { key: 'youtube', icon: <FaYoutube size={24} color={color_sm} />, link: 'https://www.youtube.com/user/niharika1972', label: 'YouTube' },
    { key: 'twitter', icon: <FaTwitter size={24} color={color_sm} />, link: 'https://twitter.com/niharikamomtaz?lang=bn', label: 'Twitter' },
    { key: 'instagram', icon: <FaInstagram size={24} color={color_sm} />, link: 'https://www.instagram.com/niharikamomtaz/?hl=en', label: 'Instagram' },
    { key: 'pinterest', icon: <FaPinterest size={24} color={color_sm} />, link: 'https://www.pinterest.com/niharikamomtaz/_saved/', label: 'Pinterest' },
    { key: 'linkedin', icon: <FaLinkedin size={24} color={color_sm} />, link: 'https://www.linkedin.com/in/niharika-momtaz-ab200b85/', label: 'LinkedIn' },
    { key: 'tiktok', icon: <SiTiktok size={24} color={color_sm} />, link: 'https://www.tiktok.com/@niharika.momtaz', label: 'TikTok' },
  ];

  if (usePatternOne) {
    // Pattern One: Render with icons and tooltips (existing pattern)
    return (
      <Space direction="horizontal" style={{marginTop:"10px"}}>
        {socialMediaItems.map((item) => (
          <Tooltip title={item.label} key={item.key}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.icon}
            </a>
          </Tooltip>
        ))}
      </Space>
    );
  } else {
    // Pattern Two: Placeholder, can be customized later
    return <>
      <div className="social-icons" style={{ fontSize: '20px' }}>
        <a href="https://www.facebook.com/niharikamomtazofficial?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          <FacebookOutlined />
        </a>
        <a href="https://www.youtube.com/user/niharika1972" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
          <YoutubeOutlined />
        </a>
        <a href="https://twitter.com/niharikamomtaz?lang=bn" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
          <TwitterOutlined />
        </a>
        <a href="https://www.instagram.com/niharikamomtaz/?hl=en" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
          <InstagramOutlined />
        </a>
        <a href="https://www.pinterest.com/niharikamomtaz/_saved/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
          <PinterestOutlined />
        </a>
        <a href="https://www.linkedin.com/in/niharika-momtaz-ab200b85/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
          <LinkedinOutlined />
        </a>
        <a href="https://www.tiktok.com/@niharika.momtaz" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
          {/* TikTok icon can use inline SVG */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02c.08 1.53.63 3.09 1.75 4.17c1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97c-.57-.26-1.1-.59-1.62-.93c-.01 2.92.01 5.84-.02 8.75c-.08 1.4-.54 2.79-1.35 3.94c-1.31 1.92-3.58 3.17-5.91 3.21c-1.43.08-2.86-.31-4.08-1.03c-2.02-1.19-3.44-3.37-3.65-5.71c-.02-.5-.03-1-.01-1.49c.18-1.9 1.12-3.72 2.58-4.96c1.66-1.44 3.98-2.13 6.15-1.72c.02 1.48-.04 2.96-.04 4.44c-.99-.32-2.15-.23-3.02.37c-.63.41-1.11 1.04-1.36 1.75c-.21.51-.15 1.07-.14 1.61c.24 1.64 1.82 3.02 3.5 2.87c1.12-.01 2.19-.66 2.77-1.61c.19-.33.4-.67.41-1.06c.1-1.79.06-3.57.07-5.36c.01-4.03-.01-8.05.02-12.07z" />
          </svg>
        </a>
      </div>

    </>; // This can be replaced with a different layout or component later
  }
};

export default SocialMediaLinks;
