import React, { useState } from 'react';
import { Row, Col, Card, Typography, Divider, Modal, Button } from 'antd';
import { FacebookOutlined, InstagramOutlined, GlobalOutlined, LinkedinOutlined } from '@ant-design/icons';
import img1 from '../assets/img/brandz/1.jpg';
import img2 from '../assets/img/brandz/4.jpg';
import img3 from '../assets/img/brandz/zenana.jpg';
import img4 from '../assets/img/brandz/metamoina.png';
import img5 from '../assets/img/brandz/nmp.jpg';

const { Title } = Typography;

// Define types for social media links
interface SocialLink {
  label: string;
  url: string;
  icon: React.ReactNode;
}

// Define types for the brand data
interface Brand {
  name: string;
  image: string;
  description: string;
  detailedDescription: string;
  links: SocialLink[];
}

const brands: Brand[] = [
  {
    name: '1972nineteenseventytwo',
    image: img1,
    description: 'Couture Jewelry by Niharika',
    detailedDescription: '1972 is a luxury brand offering couture jewelry by Niharika, located in Dubai & Dhaka.',
    links: [
      { label: 'Facebook', url: 'https://www.facebook.com/OutLiner.zenana/', icon: <FacebookOutlined /> },
      { label: 'Instagram', url: 'https://www.instagram.com/1972nineteenseventytwo/?hl=en', icon: <InstagramOutlined /> },
    ],
  },
  {
    name: '1972 Conscious',
    image: img2,
    description: 'Eco-friendly products for a sustainable future',
    detailedDescription: '1972 Conscious provides eco-friendly products aimed at promoting a sustainable future.',
    links: [
      // { label: 'Website', url: 'https://www.1972conscious.com/', icon: <GlobalOutlined /> },
      { label: 'Facebook', url: 'https://www.facebook.com/1972Conscious?mibextid=LQQJ4d', icon: <FacebookOutlined /> },
      { label: 'Instagram', url: 'https://instagram.com/1972conscious?igshid=YmMyMTA2M2Y=', icon: <InstagramOutlined /> },
    ],
  },
  {
    name: 'Zenana',
    image: img3,
    description: 'Modern fashion for the contemporary woman',
    detailedDescription: 'Zenana offers modern fashion for the contemporary woman, combining elegance and comfort.',
    links: [
      { label: 'Facebook', url: 'https://www.facebook.com/people/Zenana/100054405284831/?mibextid=LQQJ4d', icon: <FacebookOutlined /> },
    ],
  },
  {
    name: 'Meta Moina',
    image: img4,
    description: 'Meta Moina, the first ever Bangladeshi decentralized Fashion NFT',
    detailedDescription: 'Meta Moina, the first ever Bangladeshi decentralized Fashion NFT and Art NFT gallery in the metaverse. We are COMPLETELY CARBON-NEGATIVE NFT Project',
    links: [
      { label: 'Facebook', url: 'https://www.facebook.com/share/1BHZcXBpdA/', icon: <FacebookOutlined /> },
      { label: 'Instagram', url: 'https://www.instagram.com/metamoina/', icon: <InstagramOutlined /> },
      { label: 'Website', url: 'http://www.metamoina.io', icon: <GlobalOutlined /> },
      { label: 'LinkedIn', url: 'https://www.linkedin.com/company/meta-moina/', icon: <LinkedinOutlined /> },
    ],
  },
  {
    name: 'Niharika Momtaz Production',
    image: img5,
    description: 'Our mission is threefold – to distribute the richness of Bangla Cinema',
    detailedDescription: 'Our mission is threefold – to distribute the richness of Bangla Cinema, providing a platform for Bengali filmmakers to reach wider audiences, and to promote the diverse tapestry of Bangladeshi art and culture. Aspiring to transcend geographical boundaries by bringing the beauty and essence of Bangladeshi art and culture to audiences worldwide, our commitment extends beyond merely showcasing Bengali films in Dubai; we aim to be a catalyst for cultural exchange on an international stage by hosting film events, shows, and making shooting in Dubai more accesible.',
    links: [
      { label: 'Facebook', url: 'https://www.facebook.com/profile.php?id=61553900793926&mibextid=ZbWKwL', icon: <FacebookOutlined /> },
    ],
  },
];

const Brandz: React.FC = () => {
  // State for modal visibility and selected brand
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);

  const showModal = (brand: Brand) => {
    setSelectedBrand(brand);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedBrand(null);
  };

  return (
    <div style={{ color: '#fff' }}>
      <Title level={2} style={{ color: '#FFD700', textAlign: 'center' }}>
        BRANDS
      </Title>
      <Divider style={{ borderColor: 'gold', margin: '0 auto' }} />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px', // Space between buttons
          justifyContent: 'center', // Center the buttons
          alignItems: 'center',
          textAlign: 'center',
          width: '100%', // Make it responsive
          padding: '8px 0',
        }}
      >
        {brands.map((brand, index) => (
          <Card
            style={{
              backgroundColor: '#1f1f1f',
              textAlign: 'center',
              borderColor: 'gold',
              width: '250px',
              height: '350px',
              margin: '0 auto',
            }}
            cover={
              <img
                alt={brand.name}
                src={brand.image}
                style={{ padding: '20px', height: '180px', objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => showModal(brand)}
              />
            }
            onClick={() => showModal(brand)}
          >
            <Title level={4} style={{ color: 'gold' }}>{brand.name}</Title>
            <p style={{ color: '#aaa' }}>{brand.description}</p>
          </Card>
        ))}
      </div>
      {selectedBrand && (
        <Modal
          title={<Title level={3} style={{ color: '#FFD700', backgroundColor: "#1f1f1f", textAlign: 'center' }}>{selectedBrand.name}</Title>}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <div style={{ textAlign: 'center', }}><Button key="close" onClick={handleCancel} style={{ backgroundColor: 'gold', color: '#1f1f1f', }}>
              Close
            </Button></div>,
          ]}
          styles={{
            content: { backgroundColor: '#1f1f1f', color: '#fff', textAlign: 'center' }, // turns the Modal red
          }}
          closable={false}
        >
          <p style={{ color: '#aaa' }}>{selectedBrand.detailedDescription}</p>
          <div style={{ marginTop: '20px' }}>
            {selectedBrand.links.map((link, idx) => (
              <a href={link.url} target="_blank" rel="noopener noreferrer" style={{ color: 'gold', marginRight: '8px' }}>
                {link.icon}
              </a>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Brandz;
